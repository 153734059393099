import $ from 'jquery';

class ImageLoader {
	constructor(lazy) {
        this.base_url = $('meta[name="base-url"]').attr('content');
        this.resizeTimeout = null;
        this.resizeTimeoutBackground = null;
        this.lazy = lazy;

        this.events();
	}

	events() {
        const that = this;
	    
        this.resizeImages();
        this.resizeBackgroundImages();

	    $(top.document).ajaxSuccess(function() {
            that.resizeImages();
	        that.resizeBackgroundImages();
	    });

	    $(document).on('boomcms:chunkload', function(e) {
            that.resizeImages($(e.target));
	        that.resizeImages($(e.target).find('[data-asset]'));

            that.resizeBackgroundImages($(e.target));
	        that.resizeBackgroundImages($(e.target).find('[data-asset]'));
	    });

	    $(window).resize(function() {
	        if (that.resizeTimeout !== null) {
	            clearTimeout(that.resizeTimeout);
	        }

	        that.resizeTimeout = setTimeout(that.resizeImages, 500);

            if (that.resizeTimeoutBackground !== null) {
                clearTimeout(that.resizeTimeoutBackground);
            }

            that.resizeTimeoutBackground = setTimeout(that.resizeBackgroundImages, 500);
	    });
	}

    resizeImages() {
        const that = this,
              $dataImage = $('[data-image]');

        $dataImage.each(function() {
            let currentItem = $(this),
                width = Math.ceil((currentItem.width() / 100) * 100),
                height = Math.ceil((currentItem.height() / 100) * 100),
                url = '/asset/' + currentItem.attr('data-image') + '/crop/' + width + '/' + height;

            // Check if retina screen then double width and height
            if (window.devicePixelRatio >= 2) {
                url = '/asset/' + currentItem.attr('data-image') + '/view/0/' + (height*2);
            }

            if (parseInt(currentItem.attr('data-image')) > 0) {
                if (typeof(currentItem.attr('data-width')) === 'undefined' || width > parseInt(currentItem.attr('data-width')) + 20) {
                    currentItem.attr('data-width', width);

                    if (currentItem.is('img')) {
                        currentItem.attr('src', url);                        
                    }
                }
            }
        });
    }

	resizeBackgroundImages() {
		const that = this;

        $('[data-asset]').each(function() {
            let currentItem = $(this),
                width = Math.ceil((currentItem.width() / 100) * 100),
                height = Math.ceil((currentItem.height() / 100) * 100),
                url = '/asset/' + currentItem.attr('data-asset') + '/crop/' + width + '/' + height;

            // Check if retina screen then double width and height
            if (window.devicePixelRatio >= 2) {
                url = '/asset/' + currentItem.attr('data-asset') + '/crop/' + (width*2) + '/' + (height*2);
            }

            if (parseInt(currentItem.attr('data-asset')) > 0) {
                if (typeof(currentItem.attr('data-width')) === 'undefined' || width > parseInt(currentItem.attr('data-width')) + 20) {
                    currentItem.attr('data-width', width);

                    if (currentItem.hasClass('lazy')) {
                        currentItem.attr('data-src', url);
                        // that.lazy.update();
                    } else {
                        currentItem.css('background-image', 'url(' + url + ')');
                    }
                }
            }
        });
	}

    load(id) {
        const that = this,
              currentItem = $('.activity-image'),
              infowindow = $('.activity-detail'),
              width = Math.ceil((infowindow.width() / 100) * 100),
              height = Math.ceil((infowindow.height() / 2)),
              url = `/asset/${ id }/crop/${ width }/${ height }`;

        // Check if retina screen then double width and height
        // if (window.devicePixelRatio >= 2) {
        //     url = `/asset/${ id }/crop/(${width} * 2)/(${height} * 2)`;
        // }

        currentItem.attr('data-width', width);

        if(currentItem.hasClass('lazy')){
            currentItem.attr('data-src', url);
            currentItem.attr('src', url);
            // that.checkLazyLoading('.lazy');
        } else {
            currentItem.attr('src', url);
        }
    }
}

export default {
    init(lazy) {
        this.imageLoader = new ImageLoader(lazy);
    },
    load(id) {
        this.imageLoader.load(id);
    },
    resizeImages() {
        this.imageLoader.resizeImages();
    },
    resizeBackgroundImages() {
        this.imageLoader.resizeBackgroundImages();
    }
};
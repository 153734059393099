import $ from 'jquery';

class CookieLoader {
	constructor(data) {
		this.cookieName = 'hideCookieBanner';
		this.cookieClose = $(data.close);
		this.cookie = $(data.wrapper);

		this.events();
	}

	events() {
		const that = this;

        if (document.cookie.indexOf(this.cookieName) === -1) {
            that.cookie.slideDown();
            that.cookie.removeClass('d-none');
        } else {
            that.setCookie();
        }

		this.cookieClose.on('click', function(e) {
			e.preventDefault();

			that.setCookie();
			that.cookie.slideUp();
            that.cookie.addClass('d-none');
		});
	}

    /**
     * Sets a cookie to hide the banner which expires after 1 year.
     * @returns {undefined}
     */
	setCookie() {
        var d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));

        document.cookie = this.cookieName + "=yes; expires=" + d.toUTCString() + "; path=/";
	}
}

export default {
	new(data) {
		new CookieLoader(data);
	}
};
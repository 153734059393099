class MapStyles {
    constructor() {
        const mapStyles = [
          {
          "featureType": "administrative.country",
          "elementType": "geometry",
          "stylers": [
          {
          "lightness": "0"
          },
          {
          "weight": "0.33"
          },
          {
          "color": "#646464"
          },
          {
          "visibility": "on"
          }
          ]
          },
          {
          "featureType": "administrative.country",
          "elementType": "labels.text",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "administrative.country",
          "elementType": "labels.icon",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "administrative.province",
          "elementType": "geometry.stroke",
          "stylers": [
          {
          "color": "#969696"
          }
          ]
          },
          {
          "featureType": "administrative.locality",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "simplified"
          }
          ]
          },
          {
          "featureType": "administrative.locality",
          "elementType": "geometry",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "administrative.locality",
          "elementType": "geometry.stroke",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "administrative.locality",
          "elementType": "labels",
          "stylers": [
          {
          "visibility": "simplified"
          }
          ]
          },
          {
          "featureType": "administrative.locality",
          "elementType": "labels.text",
          "stylers": [
          {
          "weight": "1.69"
          },
          {
          "color": "#5f5c5c"
          }
          ]
          },
          {
          "featureType": "administrative.locality",
          "elementType": "labels.icon",
          "stylers": [
          {
          "visibility": "simplified"
          }
          ]
          },
          {
          "featureType": "administrative.land_parcel",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "administrative.land_parcel",
          "elementType": "geometry",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "landscape.natural.landcover",
          "elementType": "geometry",
          "stylers": [
          {
          "saturation": "6"
          },
          {
          "weight": "1.25"
          }
          ]
          },
          {
          "featureType": "landscape.natural.terrain",
          "elementType": "geometry.fill",
          "stylers": [
          {
          "saturation": "8"
          }
          ]
          },
          {
          "featureType": "poi",
          "elementType": "geometry.fill",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "poi.attraction",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "on"
          }
          ]
          },
          {
          "featureType": "poi.business",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "poi.government",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "poi.medical",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "poi.park",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "on"
          }
          ]
          },
          {
          "featureType": "poi.place_of_worship",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "poi.school",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "poi.sports_complex",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
          {
          "visibility": "on"
          }
          ]
          },
          {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
          {
          "visibility": "on"
          },
          {
          "color": "#fbfbfb"
          }
          ]
          },
          {
          "featureType": "road.local",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
          {
          "visibility": "on"
          },
          {
          "color": "#696c6a"
          }
          ]
          },
          {
          "featureType": "transit.station",
          "elementType": "all",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          },
          {
          "featureType": "transit.station.airport",
          "elementType": "labels.icon",
          "stylers": [
          {
          "visibility": "on"
          }
          ]
          },
          {
          "featureType": "transit.station.bus",
          "elementType": "labels.icon",
          "stylers": [
          {
          "visibility": "on"
          }
          ]
          },
          {
          "featureType": "transit.station.rail",
          "elementType": "labels.icon",
          "stylers": [
          {
          "visibility": "on"
          }
          ]
          },
          {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
          {
          "saturation": "-39"
          },
          {
          "gamma": "0.83"
          },
          {
          "hue": "#00cdff"
          },
          {
          "lightness": "-2"
          }
          ]
          },
          {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
          {
          "color": "#4a998f"
          }
          ]
          },
          {
          "featureType": "water",
          "elementType": "labels",
          "stylers": [
          {
          "visibility": "off"
          }
          ]
          }
        ];

        return mapStyles;
    }
}

export default MapStyles;
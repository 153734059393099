import $ from 'jquery';

// Bootstrap requirement
import 'popper.js';

// Bootstrap JS Modules add here
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';

import Carousel from './modules/carousel';
import ImageLoader from './modules/imageLoader';
import BannerLoader from './modules/bannerLoader';
import LightGallery from './modules/lightGallery';
import MapLoader from './modules/maps/mapLoader';
import CookieLoader from './modules/cookieLoader';
import slimScroll from 'jquery-slimscroll';
import Instafeed from 'instafeed.js';


(function() {
	ImageLoader.init();
	Carousel.init();
	BannerLoader.init();

    CookieLoader.new({
        wrapper: '#cookie-banner', 
        close: '.close'
    });

	$(document).on('click', '#carouselSlideDown', function(e) {
		e.preventDefault();
		var viewportHeight = $(window).height();

		$('html, body').animate({
			scrollTop: viewportHeight,
			complete: function () {

			}
		}, 600, 'swing');
	});

	if ($('.template-home').length) {
	    var feed = new Instafeed({
	    	get: 'user',
	    	userId: 12245878071,
	        clientId: 'c161233681cb443eb6c4a2ab4435f478',
	        accessToken: '12245878071.c161233.ab5896de2ce541f0931576687f6f6b34',
	        limit: 8,
            resolution: 'low_resolution',
            template: '<a class="instagram-section-img" href="{{link}}" target="_blank"><img src="{{image}}" alt="{{caption}}"/></a>'
	    });
	    feed.run();
	}

	$('.activity-content-wrap').slimScroll({
		height: 'calc(60vh - 48px)'
	});

	if ($('.template-rooms').length) {
		$('.carousel-inner').each(function(i, obj) {	
			LightGallery.new({
				'parent': '#'+obj.id,
				'selector': '.carousel-item'
			});
		});		
	}

	if ($('.template-gallery').length) {
		LightGallery.new({
			'parent': '#albumGallery',
			'selector': '.album-link'
		})
	}

	if ($('.template-activity').length) {
		MapLoader.new({
			container: 'activityMap'
		});
	}

	if ($('.template-activities').length) {
        MapLoader.new({
            container: 'activitiesIndex',
            index: true,
            main: true
        });
	}

	if ($('.template-contact').length) {
        MapLoader.new({
            container: 'singleMap',
            main: true
        });
	}

	$('#bottomToggler').on('click', function(e) {
		e.preventDefault();
		const $bottomHeader = $('#bottomHeaderNav');

		if ($bottomHeader.hasClass('is-active')) {
			$bottomHeader.removeClass('is-active');
			$bottomHeader.slideUp();
		} else {
			$bottomHeader.addClass('is-active');
			$bottomHeader.slideDown();
		}
	});
})();
import $ from 'jquery';
import 'bootstrap/js/dist/carousel';
import ImageLoader from './imageLoader';

class Carousel {
	constructor() {
		this.events();
	}

	events() {
	    $('.carousel-indicators').on('click', '.carousel-indicator-item', function() {
	    	ImageLoader.resizeBackgroundImages();
	    });

		$('.carousel').on('slide.bs.carousel', function () {
			ImageLoader.resizeBackgroundImages();
		});
	}
}

export default {
	init () {
		new Carousel();
	}
}
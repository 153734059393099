import $ from 'jquery';
import MapStyles from './mapStyle';
// import GoogleMapsLoader from './googleMapsLoader';
import ImageLoader from '../imageLoader';

class MapLoader {
	constructor(els) {
        this.mapContainer = document.getElementById(els.container);
        this.mapLat = parseFloat(this.mapContainer.dataset.lat ? this.mapContainer.dataset.lat : 46.7031178);
        this.mapLng = parseFloat(this.mapContainer.dataset.lng ? this.mapContainer.dataset.lng : 9.7110299);
        this.mapZoom = this.mapContainer.dataset.zoom;
        this.pageTitle = this.mapContainer.dataset.title;
        
        this.style = new MapStyles();

        this.map;
        this.latLng;
        this.markerIcon = '/vendor/boomcms/themes/bellevue/img/map_marker.svg';
        this.hotelIcon = '/vendor/boomcms/themes/bellevue/img/hotel_marker.png';

        this.init(els);

	}

	init(els) {
        const that = this,
              zoom = parseFloat(this.mapZoom ? this.mapZoom : 11);
        
        this.latLng = {lat: this.mapLat, lng: this.mapLng};

        this.mapOptions = {
            center: this.latLng,
            zoom: zoom,
            styles: that.style,
            scrollwheel: false,
            disableDefaultUI: true,
            zoomControl: true
        }

        this.map = new google.maps.Map(that.mapContainer, this.mapOptions);

        if (this.mapLat !== 0 && this.mapLng !== 0 && els.index != true) {
            that.addMarker(this.latLng);
        }

        if (els.index) {
            that.getAllActivities();
        }

        if (els.main) {
            console.log('main');
            that.getMainLocation();
        }
	}

    addMarker(latLng) {
        const that = this;

        this.marker = new google.maps.Marker({
            position: latLng,
            map: that.map,
            title: that.title,
            draggable: false,
            icon: that.markerIcon
        });

        return this.marker;
    }

    addMainMarker(latLng) {
        const that = this;

        this.marker = new google.maps.Marker({
            position: latLng,
            map: that.map,
            title: that.title,
            draggable: false,
            icon: that.hotelIcon
        });

        return this.marker;
    }

    showMarker(activity, marker) {
        const $activityDetail = $('#activityDetail'),
              $activityTitle = $('.activity-title'),
              $activityStandfirst = $('.activity-standfirst'),
              $activityView = $('#activityView'),
              $activityImage = $('.activity-image');

        google.maps.event.addListener(marker, 'click', function () {
            $activityTitle.text(activity.title);
            $activityStandfirst.html(activity.standfirst);
            
            if (activity.url !== '') {
                $activityView.show();
                $activityView.attr('data-href', activity.url);
                $activityView.on('click', function () {
                    const $this = $(this),
                          $value = $this.attr('data-href');

                    window.open(
                        $value,
                        '_blank'
                    );
                });
            } else {
                $activityView.hide();
            }

            if (activity.feature_image_url !== "") {
                // $activityImage.attr("src", activity.feature_image_url);
                ImageLoader.load(activity.feature_image_id);
            }

            $activityDetail.slideDown();
        });

        $('#activityClose').on('click', function() {
            $activityDetail.slideUp();
        });
    }

    getMainLocation() {
        const that = this,
              url = '/ajax/contact/location';

        $.get(url, function(content){
            content.map(contact => {
                if (contact.locations.length) {
                    contact.locations.map(markers => {
                        let latLng = {lat: parseFloat(markers.lat), lng: parseFloat(markers.lng)};
                        let marker = that.addMainMarker(latLng, markers.address);

                        that.showMarker(contact, marker);
                    });
                }
            });
        }, 'json');
    }

    getAllActivities() {
        const that = this,
              url = '/ajax/activities/maps';

        $.get(url, function(content){
            content.map(activity => {
                if (activity.locations.length) {
                    activity.locations.map(markers => {
                        let latLng = {lat: parseFloat(markers.lat), lng: parseFloat(markers.lng)};
                        let marker = that.addMarker(latLng, markers.address);

                        that.showMarker(activity, marker);
                    });
                }
            });
        }, 'json');
    }
}

export default {
    new(els) {
        this.map = new MapLoader(els);
    },
    index(els) {
        this.map = new MapLoader(els);
    }
}
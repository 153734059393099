import $ from 'jquery';
import ImageLoader from './imageLoader';

class BannerLoader {
	constructor() {
		this.events();
	}

	events() {
		this.bannerSwitcher();
	}

	bannerSwitcher() {
		const $bannerItem = $('.banner-switch-item');

		$bannerItem.on('mouseover', '.banner-switch-img', function() {
			const $this = $(this),
				  $thisBanner = $this.parent();

			$thisBanner.hide();
			$thisBanner.siblings().show();

			ImageLoader.resizeBackgroundImages();
		});
	}
}

export default {
	init() {
		new BannerLoader();
	}
};